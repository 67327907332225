.tarjeta-main-image {
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.fade-in-qr { animation: fadeIn 3s; }


@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
