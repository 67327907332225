.App {
  text-align: center;
}
.view {
  background-color: #1c1e21;
  background-color: white;
  background: linear-gradient(0deg, #fdfbfb  0%, #ebedee 100%);
}

.imageFit {
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}


/* Avoid Auto Zoom in inputs form on Safari IOS */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}